import React from 'react';

import ToolbarItem from './ToolbarItem';
import ToolbarItemGroup from './ToolbarItemGroup';

import './Toolbar.css';

const Toolbar = ({ id, showHelp, onAddComp, onShuffle, onRandomPairs, openGoogleFonts, onPrint, copyCss, copyHTML, htmlCodeWrapperId, cssCodeWrapperId, className }) => (
  <nav id={id} className={`toolbar navbar navbar-expand-sm navbar-light ${className} justify-content-between d-print-none`}>
    <span className="d-none d-lg-block d-xl-block text-muted">
      <small className="text-muted strong">&#9432; Click on the texts below to start pairing Google Fonts</small>
    </span>
    <div className="d-flex justify-content-end">
      <ToolbarItemGroup key="help-segment">
        <ToolbarItem icon="icon-help" onClick={showHelp} tooltip="Show Tutorial" border="left"/>
      </ToolbarItemGroup>
      <ToolbarItemGroup id="toolbar-text-segment" key="text-segment">
        <ToolbarItem icon="icon-add" onClick={onAddComp} tooltip="Add a text segment" border="left"/>
      </ToolbarItemGroup>
      <ToolbarItemGroup id="toolbar-editor-segment" key="editor-segment">        
        <ToolbarItem icon="icon-surprise" onClick={onRandomPairs} tooltip="Surprise me" border="left"/>
        <ToolbarItem icon="icon-shuffle" onClick={onShuffle} tooltip="Shuffle the phrases"  border="none"/>
      </ToolbarItemGroup>
      <ToolbarItemGroup id="toolbar-copy-segment" key="copy-segment">
        <ToolbarItem icon="icon-code" label="Copy HTML" onClick={copyHTML} data-clipboard-target={`#${htmlCodeWrapperId}`} className="clipboard-button" border="none" />
        <ToolbarItem icon="icon-code" label="Copy CSS" onClick={copyCss} data-clipboard-target={`#${cssCodeWrapperId}`} className="clipboard-button" border="none" />
      </ToolbarItemGroup>
      <ToolbarItemGroup id="toolbar-export-segment" key="export-segment">
        <ToolbarItem icon="icon-google-fonts" onClick={openGoogleFonts} tooltip="Open in Google Fonts" border="left" />
        <ToolbarItem icon="icon-print" onClick={onPrint} tooltip="Print the editor" border="left" />
      </ToolbarItemGroup>
    </div>
  </nav>
);

export default Toolbar;