import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import FontList from '../FontList';
import FontSearch from '../FontSearch';
import EmojiWrapper from '../EmojiWrapper';

import LogoType from '../LogoType';

import './Navbar.css';

const InActiveContent = ({ className, visible, style }) => (
  <div className={`inactive-navbar-content ${className} ${visible ? 'inactive-navbar-content--visible' : ''}`} style={style}>
    <Link className="navbar-brand" to="/"><LogoType /></Link>
    <span className="d-none navbar-brand-tagline">
      <small className="text-less-muted">Pair Google Fonts like a Boss <EmojiWrapper description="Boss with glasses">😎</EmojiWrapper> </small>
    </span>
  </div>
);

const ActiveContent = ({ className , visible, style, fonts, onFontSelect, selectedFont, onSearch, loadNextPage, searchTerm }) => (
  <div className={`active-navbar-content ${visible ? 'active-navbar-content--visible' : ''} ${className}`} style={style} >
    <FontSearch onSearch={onSearch} defaultSearchTerm={searchTerm} />
    <FontList fonts={fonts} onFontSelect={onFontSelect} selectedFont={selectedFont} loadNextPage={loadNextPage} />
  </div>
);

const UnstyledNavbar = ({ id, className, mode, fonts, handleFontSelect, selectedFont, onSearch, loadNextPageFonts, searchTerm }) => {
  const stopPropagation = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  let component = '';
  let aboutLink = '';
  if (mode === 'ACTIVE') {
    component = (
      <ActiveContent
        fonts={fonts}
        onFontSelect={handleFontSelect}
        selectedFont={selectedFont}
        visible={mode === 'ACTIVE'}
        onSearch={onSearch}
        loadNextPage={loadNextPageFonts}
        searchTerm={searchTerm}
      />
    );
  } else {
    component = (
      <InActiveContent visible={mode !== 'ACTIVE'} />
    );
    aboutLink = (
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" href="https://chalamphong.com" title="Built with Love by Chalamphong" target="_blank">
            <EmojiWrapper description="Built with Love">
              🛠 👨‍💻 ❤️
            </EmojiWrapper>
          </a>
        </li>
      </ul>
    );
  }
  return (
    <nav id={id} className={`navbar navbar-expand-lg ${className} justify-content-between d-print-none`} onClick={stopPropagation}>
      {
        component
      }
      {
        aboutLink
      }
    </nav>
  );
};

const Navbar = styled(UnstyledNavbar)`
  box-shadow: 0 -1px 18px #ededed;
  background: ${props => props.mode === 'ACTIVE' ? 'var(--active-navbar-color)' : 'white'};
  transition: background-color 150ms linear;
  height: 58px;
  padding: 0 20px;
`;

UnstyledNavbar.defaultProps = {
  className: '',
  id: '',
  searchTerm: '',
  // Possible Values: IN-ACTIVE, ACTIVE
  mode: 'IN-ACTIVE',
};

export default Navbar;
