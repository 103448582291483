import React from 'react';

const FancyHeader1 = ({ className, color, children, ...rest}) => (
  <h1 className={`header-1 fancy ${color} ${className}`} {...rest}>
    {
      children
    }
  </h1>
);

FancyHeader1.defaultProps = {
  color: 'grey',
  className: '',
};

export default FancyHeader1;