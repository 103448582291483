import React from 'react';

const Row = ({ className, style, children }) => (
  <div className={`row ${className}`} style={style}>
    {
      children
    }
  </div>
);

Row.defaultProps = {
  className: '',
  style: {},
};

export default Row;
