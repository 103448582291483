import React from 'react';

const Header2 = ({ className, color, children, ...rest}) => (
  <h2 className={`header-2 ${color} ${className}`} {...rest}>
    {
      children
    }
  </h2>
);

Header2.defaultProps = {
  color: 'grey',
  className: '',
};

export default Header2;