import React from 'react';

import './CookieConsent.css';

import Button from '../../components/Button';
import { BUTTON_MODE_PRIMARY, BUTTON_SIZE_SMALL } from '../../utils/Constants';
import Typography from '../Typography';

const CookieConsent = ({ show, acknowledgeCookieConsent }) => (
  <div className={`cookie-policy-wrapper ${show ? 'show' : 'hidden'}`}>
    <div className="cookie-policy">
      <Typography.Header3>
        🍪 Cookie Announcement
      </Typography.Header3>
      <Typography.Paragraph className="cookie-policy-text">
        This site is using Cookies for Analytics and to improve Pairfonts for it’s users. By continuing to use Pairfonts, you agree to our privacy policy.
      </Typography.Paragraph>
      <Button
        mode={BUTTON_MODE_PRIMARY}
        size={BUTTON_SIZE_SMALL}
        onClick={acknowledgeCookieConsent}
      >
        Ok
      </Button>
    </div>
  </div>
);

CookieConsent.defaultProps = {
  className: '',
};

export default CookieConsent;
