import React from 'react';
import '../../static/icon-font.css';
import './layout.css';
import GlobalHead from '../components/globalHead';

const MainLayout = ({ noFooter, children }) => (
  <div>
    <GlobalHead />
    { children }
  </div>
);

export default MainLayout;