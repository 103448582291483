import React from 'react';

import {
  BUTTON_MODE_PRIMARY,
  BUTTON_MODE_SECONDARY,
  BUTTON_MODE_TRANSPARENT,
  BUTTON_MODE_TERTIARY,
  BUTTON_MODE_SUBTLE,
  BUTTON_SIZE_REGULAR,
  BUTTON_SIZE_SMALL,
  BUTTON_SIZE_LARGE,
} from '../../utils/Constants';

import './Button.css';

const Button = ({
  className,
  mode,
  size,
  onClick,
  ...rest
}) => {
  let buttonClassName = `btn ${className}`;
  switch (mode) {
    case BUTTON_MODE_PRIMARY:
      buttonClassName = `${buttonClassName} btn--primary`;
      break;
    case BUTTON_MODE_SECONDARY:
      buttonClassName = `${buttonClassName} btn--secondary`;
      break;
    case BUTTON_MODE_TRANSPARENT:
      buttonClassName = `${buttonClassName} btn--transparent`;
      break;
    case BUTTON_MODE_TERTIARY:
      buttonClassName = `${buttonClassName} btn--tertiary`;
      break;
    case BUTTON_MODE_SUBTLE:
      buttonClassName = `${buttonClassName} btn--subtle`;
      break;
    default:
      break;
  }
  switch (size) {
    case BUTTON_SIZE_REGULAR:
      buttonClassName = `${buttonClassName} btn--regular`;
      break;
    case BUTTON_SIZE_SMALL:
      buttonClassName = `${buttonClassName} btn--small`;
      break;
    case BUTTON_SIZE_LARGE:
      buttonClassName = `${buttonClassName} btn--large`;
      break;
    default:
      break;
  }

  return (
    <button
      className={buttonClassName}
      onClick={onClick}
      {...rest}
    />
  );
};

Button.defaultProps = {
  className: '',
  mode: BUTTON_MODE_PRIMARY,
  size: BUTTON_SIZE_REGULAR,
};

export default Button;
