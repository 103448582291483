import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Clipboard from 'clipboard';

import GoogleFontsApi from '../utils/fontsApi/googleFontsApi';
import FontDownloader from '../utils/fontDownloader';
import ActivityHelper from '../utils/activityHelper';

import Layout from '../layouts';
import Navbar from '../components/Navbar';
import Grid from '../components/Grid';
import CodeBlock from '../components/CodeBlock';
import IntroModal from '../components/Intro';
import Modal from '../components/Modal';
import EmojiWrapper from '../components/EmojiWrapper';
import CookieConsent from '../components/CookieConsent';

import Toolbar from '../components/Toolbar';

import Editor from '../components/Editor';

import sampleTexts from '../assets/sampleTexts';

import {
  MENU_TYPE_TEXT_ALIGNMENT,
  MENU_TYPE_TEXT_LINE_HEIGHT,
  MENU_TYPE_TEXT_SIZE,
  MENU_TYPE_FONT_VARIANT,
  MENU_TYPE_LETTER_SPACING,
  MENU_TYPE_LOCK,
  MENU_TYPE_REMOVE,
  EVENT_INSERT_COMPONENT,
  EVENT_REMOVE_COMPONENT,
  EVENT_SELECT_FONT,
  EVENT_RANDOMIZE_FONTS,
  EVENT_RANDOMIZE_PHRASES,
  EVENT_OPEN_GOOGLE_FONTS,
  EVENT_PRINT,
  EVENT_CHANGE_ALIGNMENT,
  EVENT_CHANGE_VARIANT,
  EVENT_CHANGE_FONT_SIZE,
  EVENT_CHANGE_LINE_SPACING,
  EVENT_CHANGE_LETTER_SPACING,
  EVENT_CHANGE_LOCK,
  EVENT_SEARCH_FONT,
} from '../utils/Constants';

import '../styles/App.css';

const CATEGORIES = ['sans-serif', 'serif', 'monospace', 'handwriting', 'display'];
const SAMPLE_TEXT_LENGTH_SHORT = 'short';
const SAMPLE_TEXT_LENGTH_LONG = 'long';
const LOCAL_STORAGE_INTRO_SHOW_KEY = 'did_show_intro';
const LOCAL_STORAGE_COOKIE_CONSENT_KEY = 'did_show_cookie_consent';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fonts: [],
      fontsLoaded: false,
      appLoaded: false,
      categories: CATEGORIES,
      fontMode: 'IN-ACTIVE',
      page: 1,
      fontsPerPage: 30,
      comps: [],
      searchTerm: '',
      showCSSCopySuccess: false,
      showHTMLCopySuccess: false,
      showCookieConsent: false,
    };

    this.shouldShowCookieConsent = false;

    this.handleFontSearch = this.handleFontSearch.bind(this);
    this.openInGoogleFonts = this.openInGoogleFonts.bind(this);
    this.onTextCompActive = this.onTextCompActive.bind(this);
    this.loadNextPageFonts = this.loadNextPageFonts.bind(this);
    this.handleFontSelect = this.handleFontSelect.bind(this);
    this.shuffleTexts = this.shuffleTexts.bind(this);
    this.showOnboardingModal = this.showOnboardingModal.bind(this);
    this.randomizeFonts = this.randomizeFonts.bind(this);
    this.print = this.print.bind(this);
    this.insertCompInState = this.insertCompInState.bind(this);
    this.acknowledgeCookieConsent = this.acknowledgeCookieConsent.bind(this);
  }

  componentDidMount() {
    this.fetchFonts();
    var clipboard = new Clipboard('.clipboard-button');
    clipboard.on('success', (e) => {
      const target = e.trigger.getAttribute('data-clipboard-target');
      e.clearSelection();
      if (target.includes('css')) {
        this.setState({
          showCSSCopySuccess: true,
        });
        setTimeout(() => {
          this.setState({
            showCSSCopySuccess: false,
          });
        }, 2000);
      } else if (target.includes('html')) {
        this.setState({
          showHTMLCopySuccess: true,
        });
        setTimeout(() => {
          this.setState({
            showHTMLCopySuccess: false,
          });
        }, 2000);
      }
    });

    if (window && window.localStorage) {
      const didShowIntroValue = window.localStorage.getItem(LOCAL_STORAGE_INTRO_SHOW_KEY);
      const didShowCookieConsent = window.localStorage.getItem(LOCAL_STORAGE_COOKIE_CONSENT_KEY);
      if (didShowIntroValue !== 'Y') {
        this.showOnboardingModal();
        // Mark intro as shown
        window.localStorage.setItem(LOCAL_STORAGE_INTRO_SHOW_KEY, 'Y');
      }
      if (didShowCookieConsent !== 'Y') {
        // Mark intro as shown
        this.shouldShowCookieConsent = true;
      }
    }

    // Trigger tooltips
    if (window && window.$) {
      var j = window.$;
      j(function () {
        j('[data-toggle="tooltip"]').tooltip({
          trigger : 'hover',
          template: '<div class="tooltip d-print-none" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
        })
      });
    }
  }

  // Utils
  isFontSizeLarge(size) {
    return size > 40;
  }
  getMenuItemsForComp(align, size, lineHeight, variant, letterSpacing, isLocked) {
    const textSegmentLockMenuItem = {
      type: MENU_TYPE_LOCK,
      title: 'lock / unlock text segment',
      value: isLocked,
      onChange: this.handleCompTextLockStatus.bind(this),
    };
    const textAlignMenuItem = {
      type: MENU_TYPE_TEXT_ALIGNMENT,
      title: 'text alignment',
      value: align,
      onChange: this.handleCompTextAlignChange.bind(this),
    };
    const textLineHeightMenuItem = {
      type: MENU_TYPE_TEXT_LINE_HEIGHT,
      title: 'line spacing',
      value: lineHeight,
      onChange: this.handleLineHeightChange.bind(this),
    };
    const fontVariantMenuItem = {
      type: MENU_TYPE_FONT_VARIANT,
      title: 'font variant',
      value: variant,
      onChange: this.handleWeightChange.bind(this),
    };
    const textSizeMenuItem = {
      type: MENU_TYPE_TEXT_SIZE,
      title: 'font size',
      value: size,
      onChange: this.handleFontSizeChange.bind(this),
    };
    const textLetterSpacingMenuItem = {
      type: MENU_TYPE_LETTER_SPACING,
      title: 'letter spacing',
      value: letterSpacing,
      onChange: this.handleLetterSpacingChange.bind(this),
    };
    const deleteMenuItem = {
      type: MENU_TYPE_REMOVE,
      title: 'remove text segment',
      onChange: this.removeCompFromState.bind(this),
    }

    return [
      textAlignMenuItem,
      fontVariantMenuItem,
      textSizeMenuItem,
      textLineHeightMenuItem,
      textLetterSpacingMenuItem,
      textSegmentLockMenuItem,
      deleteMenuItem,
    ];
  }

  fetchFonts() {
    GoogleFontsApi.fetchFonts('popularity')
      .then((fontResponse) => {
        const primaryFont = fontResponse.items[0];
        let primaryWeight = primaryFont.variants[primaryFont.variants.length - 1];
        primaryWeight = primaryWeight.replace('italic', '');

        const secondaryFont = fontResponse.items[1];
        this.setState({
          fontsLoaded: true,
          appLoaded: true,
          fonts: fontResponse.items,
          comps: [{
            index: 1,
            type: 'p',
            font: primaryFont,
            settings: {
              weight: primaryWeight,
              size: 64,
              align: 'left',
              lineHeight: 1.2,
              fontStyle: 'normal',
              letterSpacing: 0,
              isLocked: false,
            },
            defaultText: this.sampleText(SAMPLE_TEXT_LENGTH_SHORT),
            isActive: false,
            menuItems: this.getMenuItemsForComp('left', 64, 1.2, primaryWeight, 0, false),
          }, {
            index: 2,
            type: 'p',
            font: secondaryFont,
            settings: {
              weight: secondaryFont.variants[0],
              size: 32,
              align: 'left',
              lineHeight: 1.5,
              fontStyle: 'normal',
              letterSpacing: 0,
              isLocked: false,
            },
            defaultText: this.sampleText(SAMPLE_TEXT_LENGTH_LONG),
            isActive: false,
            menuItems: this.getMenuItemsForComp('left', 32, 1.5, secondaryFont.variants[0], 0, false),
          }],
        });

        FontDownloader.download('google', `${primaryFont.family}:${primaryFont.variants.join(',')}`, primaryFont.category);
        FontDownloader.download('google', `${secondaryFont.family}:${secondaryFont.variants.join(',')}`, secondaryFont.category);
      })
      .catch(e => {
        this.setState({
          fontsLoaded: true,
          appLoaded: true,
          error: e.message,
        });
      });
  }
  getFonts() {
    let fonts = this.state.fonts;
    if (this.state.categories.length > 0) {
      fonts = fonts.filter((font) => {
        return this.state.categories.includes(font.category);
      });
    }

    if (this.state.searchTerm.trim().length > 0) {
      fonts = fonts.filter((font) => {
        const fontString = `${font.family} ${font.category}`.toLowerCase();
        return fontString.includes(this.state.searchTerm);
      });
    }

    const fontsToFetch = this.state.fontsPerPage * this.state.page;
    if (fonts.length > fontsToFetch) {
      fonts = fonts.slice(0, fontsToFetch);
    }
    return fonts;
  }
  canLoadMoreFonts() {
    return this.state.fontsPerPage * this.state.page < this.state.fonts.length;
  }
  sampleText(length = SAMPLE_TEXT_LENGTH_SHORT) {
    const sampleSet = sampleTexts[length];
    const max = sampleSet.length - 1;
    const min = 0;

    const randomIndex = Math.floor(Math.random()*(max-min+1)+min);

    return sampleSet[randomIndex];
  }
  getRandomFont(fonts) {
    let max = fonts.length;
    let min = 0;
    min = Math.ceil(min);
    max = Math.floor(max);

    const randomIndex = Math.floor(Math.random() * (max - min + 1)) + min;

    return fonts[randomIndex];
  }
  getGoogleFontPath(comps) {
    // selection.family=Kanit:300i,400|Roboto:400,400i
    let path = 'selection.family=';
    let fontSettings = [];
    if (Array.isArray(comps) && comps.length > 0) {
      fontSettings = comps.map((comp) => {
        const variants = comp.font.variants;
        if (variants.includes(comp.settings.weight)) {
          return `${comp.font.family}:${comp.settings.weight}`;
        }
        return comp.font.family;
      });
    }
    return `${path}${fontSettings.join('|')}`;
  }
  parseFontWeight(weight) {
    let parsedWeight = weight;
    if (weight === 'regular' || weight === 'italic') {
      parsedWeight = '400';
    }
    const replacedParsedWeight = parsedWeight.replace(/italic/g, '');

    return replacedParsedWeight;
  }
  getCssCode(comps) {
    let fontStyles = [];
    if (Array.isArray(comps) && comps.length > 0) {
      fontStyles = comps.map((comp, index) => {
        let style = `.text-${index + 1} {`;
        style = `${style}\n  font-family: '${comp.font.family}', sans-serif;`;
        style = `${style}\n  font-size: ${comp.settings.size}px;`;

        let weight = comp.settings.weight;
        let letterSpacing = comp.settings.letterSpacing;
        let fontStyle = comp.settings.fontStyle;

        if (weight.includes('italic')) {
          style = `${style}\n  font-weight: ${this.parseFontWeight(weight)};`;
        } else {
          style = `${style}\n  font-weight: ${this.parseFontWeight(weight)};`;
        }
        if (fontStyle === 'italic') {
          style = `${style}\n  font-style: italic;`;
        }

        style = `${style}\n  line-height: ${comp.settings.lineHeight};`;
        style = `${style}\n  text-align: ${comp.settings.align};`;
        if (letterSpacing > 0) {
          style = `${style}\n  letter-spacing: ${comp.settings.letterSpacing}rem;`;
        }
        style = `${style}\n`;
        style = `${style}\n  -webkit-font-smoothing: antialiased;`;
        style = `${style}\n  -moz-osx-font-smoothing: grayscale;`;
        style = `${style}\n}`;
        return style;
      });
    }
    const comments = `/* CSS */
/* Add this to your css file */
/* replace the selector with your own */
`;
    const fontStyleString = fontStyles.join(`\n\n`);
    return(`${comments}\n${fontStyleString}`);
  }
  getHTMLCode(comps) {
    let fontStyles = [];

    if (Array.isArray(comps) && comps.length > 0) {
      const family = {};
      comps.forEach((comp, index) => {
        const compFontName = comp.font.family.replace(/\s/g, '+');
        if (!family[compFontName]) {
          family[compFontName] = [];
        }
        let weight = comp.settings.weight;
        if (!family[compFontName].includes(weight)) {
          family[compFontName] = [
            ...family[compFontName],
            weight,
          ];
        }
      });
      fontStyles = Object.keys(family).map((font) => {
        return `family=${font}:wght@${family[font].join(',')}`;
      });
    }

    const fontHTMLLink = `<link href="https://fonts.googleapis.com/css2?${fontStyles.join('&')}&display=swap" rel="stylesheet" />`;
    const htmlCode = `<!-- HTML -->
<!-- Add this in the head tag of your HTML file -->

${fontHTMLLink}`;
    return htmlCode;
  }
  getCompSettings(index, fonts, comps) {
    if (index < 3) {
      let fontIndex = index-1;
      let textLength = SAMPLE_TEXT_LENGTH_SHORT;
      if (fontIndex > 1) {
        fontIndex = 1;
      }
      const font = fonts[fontIndex];
      let weight = font.variants[font.variants.length - 1];
      weight = weight.replace('italic', '');

      let size = 24;
      if (index === 1) {
        size = 64;
      } else if (index === 2) {
        size = 32;
      }

      let lineHeight = 1.2;
      if (index !== 1) {
        lineHeight = 1.5;
        textLength = SAMPLE_TEXT_LENGTH_LONG;
      }
      const settings = {
        weight,
        size,
        align: 'left',
        lineHeight,
        fontStyle: 'normal',
        letterSpacing: 0,
        isLocked: false,
      };

      return {
        index,
        type: 'p',
        font,
        settings,
        defaultText: this.sampleText(textLength),
        isActive: false,
        menuItems: this.getMenuItemsForComp(settings.align, settings.size, settings.lineHeight, weight, 0, false),
      };
    } else {
      const lastcomp = comps[comps.length - 1];

      const newComp = {
        index,
        type: 'p',
        font: lastcomp.font,
        settings: {
          ...lastcomp.settings,
          size: 24,
          isLocked: false,
        },
        defaultText: this.sampleText(SAMPLE_TEXT_LENGTH_LONG),
        isActive: false,
        menuItems: this.getMenuItemsForComp(
          lastcomp.settings.align,
          24,
          lastcomp.settings.lineHeight,
          lastcomp.settings.weight,
          lastcomp.settings.letterSpacing,
          false,
        ),
      };

      return newComp;
    }
  }

  // State Manipulators
  setFontModeActive(compIndex) {
    this.setState({
      fontMode: 'ACTIVE',
      activeCompIndex: compIndex,
    });

    this.state.comps.forEach((comp) => {
      if (comp.index === compIndex) {
        comp.isActive = true;
      } else {
        comp.isActive = false;
      }
    });
  }
  setFontModeInActive(e) {
    if (e) {
      e.preventDefault();
    }

    const comps = this.state.comps;
    comps.forEach((comp) => {
      comp.isActive = false;
    });

    this.setState({
      fontMode: 'IN-ACTIVE',
      comps,
    });
  }
  loadNextPageFonts() {
    if (this.canLoadMoreFonts()) {
      this.setState({
        page: this.state.page + 1,
      });
    }
  }
  replaceCompInState(newComp) {
    const comps = this.state.comps;

    const newComps = comps.map((comp) => {
      if (comp.index === newComp.index) {
        return newComp;
      }
      return comp;
    });

    this.setState({
      comps: newComps,
    });
  }
  insertCompInState(e) {
    if (e) {
      e.preventDefault();
    }

    const { comps, fonts } =  this.state;
    const index = comps.length  > 0 ?
      comps[comps.length - 1].index + 1 :
      1;
    const newComp = this.getCompSettings(index, fonts, comps);
    comps.push(newComp);

    ActivityHelper.t(EVENT_INSERT_COMPONENT, {
      index,
    });

    this.setState({
      comps
    });    
  }
  removeCompFromState(index) {
    let { comps } =  this.state;

    comps = comps.filter(comp => {
      return comp.index !== index;
    });

    ActivityHelper.t(EVENT_REMOVE_COMPONENT, {
      index,
    });

    this.setState({
      comps,
    });
  }

  // Event Handlers
  print(e) {
    if (e) e.preventDefault();

    if (window) {
      ActivityHelper.t(EVENT_PRINT);
      window.print();
    }
  }
  selectedComp(index) {
    const comps = this.state.comps;
    let mutableIndex = index;

    if (typeof mutableIndex === 'undefined') {
      mutableIndex = this.state.activeCompIndex;
    }
    const selectedComp = comps.find(comp => comp.index === mutableIndex);

    return selectedComp;
  }
  handleFontSelect(font) {
    const comps = this.state.comps;
    const selectedComp = this.selectedComp();
    selectedComp.font = font;

    ActivityHelper.t(EVENT_SELECT_FONT, {
      font: font.family,
    });
    this.setState({
      comps,
    });
  }
  onTextCompActive(index) {
    this.setFontModeActive(index);
  }
  selectedFont() {
    const selectedComp = this.selectedComp();
    return selectedComp ? selectedComp.font : undefined;
  }
  handleBlur(e) {
    e.preventDefault();

    this.setFontModeInActive();
  }
  handleCompTextAlignChange(compIndex) {
    const comp = this.selectedComp(compIndex);
    if (comp) {
      const alignment = comp.settings.align;
      let newAlignment = alignment;

      if (alignment === 'center') {
        newAlignment = 'right';
      } else if (alignment === 'right') {
        newAlignment = 'left';
      } else if (alignment === 'left') {
        newAlignment = 'center';
      }

      comp.settings.align = newAlignment;

      const menuItem = comp.menuItems.find((menuItemObj) => {
        return menuItemObj.type === MENU_TYPE_TEXT_ALIGNMENT;
      });

      if (menuItem) {
        menuItem.value = newAlignment;
      }

      ActivityHelper.t(EVENT_CHANGE_ALIGNMENT, {
        index: compIndex,
        alignment: newAlignment,
      });

      this.replaceCompInState(comp);
    }
  }
  handleCompTextLockStatus(compIndex) {
    const comp = this.selectedComp(compIndex);
    if (comp) {
      const menuItem = comp.menuItems.find((menuItemObj) => {
        return menuItemObj.type === MENU_TYPE_LOCK;
      });

      menuItem.value = !comp.settings.isLocked;
      comp.settings.isLocked = !comp.settings.isLocked;

      ActivityHelper.t(EVENT_CHANGE_LOCK, {
        index: compIndex,
        changed_from: !comp.settings.isLocked,
        changed_to: comp.settings.isLocked,
      });

      this.replaceCompInState(comp);
    }
  }
  handleLineHeightChange(compIndex, newValue) {
    const comp = this.selectedComp(compIndex);
    if (comp) {
      comp.settings.lineHeight = newValue;

      const menuItem = comp.menuItems.find((menuItemObj) => {
        return menuItemObj.type === MENU_TYPE_TEXT_LINE_HEIGHT;
      });

      if (menuItem) {
        menuItem.value = newValue;
      }

      ActivityHelper.t(EVENT_CHANGE_LINE_SPACING, {
        index: compIndex,
        line_height: newValue,
      });

      this.replaceCompInState(comp);
    }
  }
  handleLetterSpacingChange(compIndex, newValue) {
    const comp = this.selectedComp(compIndex);
    if (comp) {
      comp.settings.letterSpacing = newValue;

      const menuItem = comp.menuItems.find((menuItemObj) => {
        return menuItemObj.type === MENU_TYPE_LETTER_SPACING;
      });

      if (menuItem) {
        menuItem.value = newValue;
      }

      ActivityHelper.t(EVENT_CHANGE_LETTER_SPACING, {
        index: compIndex,
        letter_spacing: newValue,
      });

      this.replaceCompInState(comp);
    }
  }
  handleFontSizeChange(compIndex, newValue) {
    const comp = this.selectedComp(compIndex);
    if (comp) {
      comp.settings.size = newValue;

      const menuItem = comp.menuItems.find((menuItemObj) => {
        return menuItemObj.type === MENU_TYPE_TEXT_SIZE;
      });

      if (menuItem) {
        menuItem.value = newValue;
      }
      ActivityHelper.t(EVENT_CHANGE_FONT_SIZE, {
        index: compIndex,
        font_size: newValue,
      });

      this.replaceCompInState(comp);
    }
  }
  handleWeightChange(compIndex, newValue) {
    const comp = this.selectedComp(compIndex);
    if (comp) {
      const parsedValue = this.parseFontWeight(newValue);
      comp.settings.weight = parsedValue;

      if (newValue.indexOf('italic') > -1) {
        comp.settings.fontStyle = 'italic';
      } else {
        comp.settings.fontStyle = 'normal';
      }

      const menuItem = comp.menuItems.find((menuItemObj) => {
        return menuItemObj.type === MENU_TYPE_FONT_VARIANT;
      });

      ActivityHelper.t(EVENT_CHANGE_VARIANT, {
        index: compIndex,
        variant: newValue,
      });

      if (menuItem) {
        menuItem.value = newValue;
      }

      this.replaceCompInState(comp);
    }
  }
  handleFontSearch(searchTerm) {
    ActivityHelper.t(EVENT_SEARCH_FONT, {
      term: searchTerm,
    });
    this.setState({
      searchTerm: searchTerm.toLowerCase(),
    });
  }
  openInGoogleFonts(e) {
    if (e) {
      e.preventDefault();
    }
    const baseURL = 'https://fonts.google.com/?';
    const googleFontPath = this.getGoogleFontPath(this.state.comps);

    ActivityHelper.t(EVENT_OPEN_GOOGLE_FONTS, {
      google_path: googleFontPath,
    });

    window.open(`${baseURL}${googleFontPath}`,'_blank');
  }
  shuffleTexts(e) {
    e.preventDefault();

    const comps = this.state.comps;
    comps.forEach(comp => {
      if (!comp.settings.isLocked) {
        let textLength = SAMPLE_TEXT_LENGTH_LONG;
        if (this.isFontSizeLarge(comp.settings.size)) {
          textLength = SAMPLE_TEXT_LENGTH_SHORT;
        }
        comp.defaultText = this.sampleText(textLength);
      }
    });

    ActivityHelper.t(EVENT_RANDOMIZE_PHRASES);

    this.setState({
      comps,
    });
  }
  randomizeFonts(e) {
    e.preventDefault();

    const {
      fonts,
      comps,
    } = this.state;

    comps.forEach((comp) => {
      if (!comp.settings.isLocked) {
        const randomFont = this.getRandomFont(fonts);
        FontDownloader.download('google', randomFont.family, randomFont.category);
        comp.font = randomFont;
      }
    });

    ActivityHelper.t(EVENT_RANDOMIZE_FONTS);

    this.setState({
      comps,
    });
  }
  showCopiedModal(e) {
    e.preventDefault();
    const j = window.$;
    j('#confirmation-modal').modal('show');
  }
  showOnboardingModal(e) {
    if (e) {
      e.preventDefault();
    }

    const j = window.$;
    j('#onboarding-modal').modal('show');
  }
  showCookieConsent() {
    this.setState({
      showCookieConsent: true,
    });
  }
  acknowledgeCookieConsent(e) {
    if (e) {
      e.preventDefault();
    }

    if (window && window.localStorage) {
      window.localStorage.setItem(LOCAL_STORAGE_COOKIE_CONSENT_KEY, 'Y');
      this.setState({
        showCookieConsent: false,
      });
      this.shouldShowCookieConsent = false;
    }
  }

  renderCookieConsent() {
    let show = this.shouldShowCookieConsent;
    if (show && !this.state.showCookieConsent) {
      setTimeout(() => {
        this.showCookieConsent()
      }, 1000);
    }
    return <CookieConsent show={this.state.showCookieConsent} acknowledgeCookieConsent={this.acknowledgeCookieConsent} />;
  }

  render() {
    return (
      <Layout>
        <div className="App" onClick={this.handleBlur.bind(this)}>
          <Helmet>
            <title>PairFonts: Tools to find the best fonts for your project</title>
            <meta property="og:site_name" content="Pairfonts" />
            <meta property="og:type" content="website" />
            <meta property="og:description" content="A tool for designers to find and pair the perfect Google fonts for any project" />
            <meta name="description" content="A tool for designers to find and pair the perfect Google fonts for any project" />
            <meta property="og:url" content="https://parifonts.com" />
            <meta property="og:title" content="PairFonts: Tools to find the best fonts for your project" />
            <meta name="twitter:card" content="summary_large_image"></meta>
            <meta name="twitter:creator" content="@chalamphong"></meta>

          </Helmet>
          <Navbar
            id="navbar"
            className="fixed-top"
            mode={this.state.fontMode}
            fonts={this.getFonts()}
            loadNextPageFonts={this.loadNextPageFonts}
            canLoadMoreFonts={this.canLoadMoreFonts()}
            handleFontSelect={this.handleFontSelect}
            selectedFont={this.selectedFont()}
            searchTerm={this.state.searchTerm}
            onSearch={this.handleFontSearch}
          />
          <Toolbar
            id="toolbar"
            className="fixed-top-ish"
            showHelp={this.showOnboardingModal}
            onShuffle={this.shuffleTexts}
            onRandomPairs={this.randomizeFonts}
            openGoogleFonts={this.openInGoogleFonts}
            copyCss={this.showCopiedModal}
            copyHTML={this.showCopiedModal}
            onPrint={this.print}
            onAddComp={this.insertCompInState}
            cssCodeWrapperId="css-code-block"
            htmlCodeWrapperId="html-code-block"
          />
          <Grid.Container className="content-wrapper" isFluid={true}>
            <Grid.Row>
              <Grid.Column
                id="editor-wrapper"
                largeSize={8}
                mediumSize={12}
                mediumOffset={0}
                smallOffset={0}
              >
                <Grid.Column
                  className="no-gutters"
                  largeSize={12}
                  mediumSize={12}
                  mediumOffset={0}
                  smallOffset={0}
                >
                  <Editor
                    fonts={this.getFonts()}
                    loaded={this.state.appLoaded}
                    selectedFont={this.state.selectedFont}
                    comps={this.state.comps}
                    onElementClick={this.onTextCompActive}
                  />
                </Grid.Column>
              </Grid.Column>
              <Grid.Column
                id="code-wrapper"
                className="d-print-none d-none d-lg-block"
                largeSize={4}
                mediumSize={12}
                mediumOffset={0}
                smallOffset={0}>
                <Grid.Column                  
                  className="html-css-wrapper"
                  smallSize={12}
                  smallOffset={0}
                >
                  <CodeBlock id="html-code-block" lang="html" code={this.getHTMLCode(this.state.comps)}/>
                  <CodeBlock id="css-code-block" lang="css" code={this.getCssCode(this.state.comps)}/>
                </Grid.Column>
            </Grid.Column>
            </Grid.Row>
          </Grid.Container>
          <IntroModal
            id="onboarding-modal"
            toolbarId="toolbar"
            activateTextComp={this.onTextCompActive}
          />
          <Modal.confirmation
            title = {(<EmojiWrapper description="Legendary Unicorn gives you a starry thumbs up">🦄👍✨</EmojiWrapper>)}
            description="Copied successfully!! Good luck with your project"
          />
          {
            this.renderCookieConsent()
          }
        </div>
      </Layout>
    );
  }
}

export default App;
