import React, { Component } from 'react';

import Toolbar from '../TextCompToolbar';

import "./TextComp.css";

export default class TextComp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };
  }
  componentDidMount() {
    const id = this.getId();    
    const element = document.getElementById(id);
    element.scrollIntoView({behavior: 'smooth'});

    this.triggerTooltip(id);
  }
  handleClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.onElementClick(this.props.index);
  }
  triggerTooltip(id) {
    var j = window.$;
    j(function () {
      console.log('Triggering tooltip', id);
      j(`#${id}`).tooltip({
        trigger : 'hover',
        template: '<div class="tooltip d-print-none" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
      })
    });
  }

  getFontSummary() {
    const fontFamily = this.props.font ? this.props.font.family : '';
    const fontVariant = this.props.settings.weight;
    return `${fontFamily}:${fontVariant}`;
  }
  getId() {
    return `text-comp-${this.props.index}`;
  }

  renderMenu() {
    return (
      <Toolbar
        isActive={this.props.isActive}
        items={this.props.menuItems}
        compIndex={this.props.index}
        variants={this.props.font.variants}
        fontFamily={this.props.font.family}
      />
    );
  }

  render() {
    const {
      index,
      font,
      settings,
      defaultText,
    } = this.props;
    const style = {
      fontFamily: `'${font.family}', sans-serif`,
      fontWeight: `${
        settings.weight === 'regular' ||
        settings.weight === 'italic' ?
        400 :
        settings.weight
      }`,
      fontSize: `${settings.size}px`,
      textAlign: `${settings.align}`,
      lineHeight: `${settings.lineHeight}`,
      fontStyle: `${settings.fontStyle}`,
      letterSpacing: `${settings.letterSpacing}rem`,
    };
    const id = this.getId();
    return (
      <div className="text-comp-wrapper">
        {
          this.renderMenu()
        }
        <p
          id={id}
          key={index}
          className="text-comp"
          onClick={this.handleClick.bind(this)}
          onFocus={this.handleClick.bind(this)}
          contentEditable="true"
          suppressContentEditableWarning="true"
          style={style}
          tabIndex={index}
          title={this.getFontSummary()}
          data-original-title={this.getFontSummary()}
          data-toggle="tooltip"
          data-placement="right"
        >
          {
            defaultText
          }
        </p>
      </div>
    );
  }
}
