import React, { Component } from 'react';

import './CodeBlock.css';

export default class CodeBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prism: undefined,
    };
    this.getHtml = this.getHtml.bind(this);
  }
  componentDidMount() {
    if (typeof window !== 'undefined') {
      import("prismjs")
        .then((Prism) => {
          this.setState({
            prism: Prism,
          });
        });
    }
  }
  getHtml() {
    if (this.state.prism) {
      let prismLang = this.state.prism.languages.css;
      let lang = this.props.lang;
      if (this.props.lang === 'html' ) {
        prismLang = this.state.prism.languages.html;
        lang = 'html';
      }
      const codeHtml = this.state.prism.highlight(
        this.props.code,
        prismLang,
        lang,
      );
      return {
        __html: codeHtml,
      };
    }
    return {
      __html: '<span class="token loading">Loading...</span>',
    };
  }
  render() {
    return (
      <pre id={this.props.id} className="code-block-wrapper">
        <code className="code-block" dangerouslySetInnerHTML={this.getHtml()} />
      </pre>
    )
  }
}