import React from 'react';

const Container = ({ className, style, isFluid, children }) => (
  <div style={style} className={`${isFluid ? 'container-fluid' : 'container'} ${className}`}>
    {children}
  </div>
);

Container.defaultProps = {
  className: '',
  style: {},
  isFluid: false,
};

export default Container;
