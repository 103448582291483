import React, { Component } from 'react';

import 'driver.js/dist/driver.min.css';
import './driver.css';

import Button from '../../components/Button';
import EmojiWrapper from '../../components/EmojiWrapper';
import Typography from '../../components/Typography';
import {
  BUTTON_MODE_PRIMARY,
  BUTTON_SIZE_SMALL,
  EVENT_INTRO_STARTED,
  EVENT_INTRO_FINISH,
} from '../../utils/Constants';
import ActivityHelper from '../../utils/activityHelper';

export default class IntroModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      steps: [
        {
          element: '#editor-wrapper',
          popover: {
            title: '🎮 This is your Playground',
            description: 'Write whatever you want and experiment with various text settings',
            position: 'right'
          }
        },
        {
          element: '#text-comp-1',
          popover: {
            title: '👉 Click on the text',
            description: 'Your click <u><b>activates</b></u> the text segment. Once active you can edit the text, change fonts & tinker with font size, weight etc',
            position: 'bottom'
          }
        },
        {
          element: '#navbar',
          popover: {
            title: '🐕 Such Font, Much choices',
            description: 'Once a text segment is activated, you\'ll see a bunch of fonts in the navbar. Click on the fonts to apply. <br />Keep scrolling to load more',
            position: 'bottom'
          }
        },
        {
          element: '#toolbar-text-segment',
          popover: {
            title: '✌️ 2 fonts not enough?',
            description: 'Click on the plus button to add more text segments. It\'s that easy. This is useful when comparing multiple fonts.',
            position: 'left'
          }
        },
        {
          element: '#toolbar-editor-segment',
          popover: {
            title: '🤷‍♀️ Don\'t know what you\'re looking for?',
            description: 'The <u><b>cake button</b></u> chooses random fonts for you.<br /> The <u><b>shuffle button</b></u> shuffles the text to give you random cool phrases',
            position: 'left'
          }
        },
        {
          element: '#toolbar-copy-segment',
          popover: {
            title: '👨‍💻 Are you a developer?',
            description: 'Use this segment to copy the HTML & CSS code and paste it in your own project',
            position: 'left'
          }
        },
        {
          element: '#toolbar-export-segment',
          popover: {
            title: '👩‍🎨 Are you a designer?',
            description: 'Open the chosen fonts in Google Fonts and download them to create some magic.<br />Or print it for your brainstorming session',
            position: 'left'
          }
        },
        {
          element: '#code-wrapper',
          popover: {
            title: '🖥 The Code Segment',
            description: 'Updates in real-time as you tinker. Quite useful if you\'re looking for something specific',
            position: 'left'
          }
        },
        // {
        //   element: '#text-segment-add-button',
        //   popover: {
        //     title: '🤩 Lastly but not the leastly <i>*sighs*</i>',
        //     description: 'You can add more text segments. Just click on this very low-key, totally non-obtrusive button<br /><i>p.s: Sorry about the lastly leastly bs</i>',
        //     position: 'right'
        //   }
        // }
      ],
    };

    this.startTutorial = this.startTutorial.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
    this.handlePreviousStep = this.handlePreviousStep.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.resetOnboarding = this.resetOnboarding.bind(this);
  }

  componentDidMount() {
    const that = this;
    import('driver.js')
      .then((driverPack) => {
        let Driver = driverPack;
        if (driverPack.default) {
          Driver = driverPack.default;
        }
        const driver = new Driver({
          animate: true,
          allowClose: false,
          onNext: that.handleNextStep,
          onReset: that.handleReset,
          onPrevious: that.handlePreviousStep,
          scrollIntoViewOptions: {
            block: 'nearest',
            inline: 'nearest',
            behavior: 'smooth',
          }
        });

        that.setState({
          driver,
        });
      });

    var j = window.$;
    j(`#${this.props.id}`).on('shown.bs.modal', function (e) {
      setTimeout( () => {
        j('.modal-backdrop').addClass('fade-scale');
      }, 200);
    });
    j(`#${this.props.id}`).on('hide.bs.modal', function (e) {
      setTimeout( () => {
        j('.modal-backdrop').removeClass('fade-scale');
      }, 200);
    });
  }

  // Utils
  startTutorial(e) {
    e && e.preventDefault();
    if (window) {
      var j = window.$;
      j(`#${this.props.id}`).modal('hide');
      const driver = this.state.driver;
      setTimeout(() => {
        driver.defineSteps(this.state.steps);
        ActivityHelper.t(EVENT_INTRO_STARTED);
        driver.start();
      }, 0);
    }
  }
  handleNextStep(element) {
    if (this.state.step === 1 || this.state.step === 2) {
      // Activate a text segment
      const { activateTextComp } = this.props;
      if (typeof activateTextComp === 'function') {
        activateTextComp(1);
      }
    } else if (this.state.step === 7) {
      // alert('Have fun');
    }
    this.setState({
      step: this.state.step + 1,
    });
  }
  handlePreviousStep(element) {
    this.setState({
      step: this.state.step - 1,
    });
  }
  handleReset(element) {
    const { step } = this.state;
    const toUpdate = {
      step: 1,
    };

    if (step === 9) {
      toUpdate.showThanks = true;
    }

    this.setState(toUpdate);
  }
  resetOnboarding(e) {
    e && e.preventDefault();

    var j = window.$;
    j(`#${this.props.id}`).modal('hide');

    ActivityHelper.t(EVENT_INTRO_FINISH);

    const that = this;
    setTimeout(() => {
      that.setState({
        showThanks: false,
      });
    }, 300);
  }
  // Render methods
  renderIntro() {
    return (
      <div className="modal fade" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header border-0">
              <Typography.Header2 className="text-center">
                <EmojiWrapper description="Hey">👋</EmojiWrapper> Hey, Welcome to Pairfonts
              </Typography.Header2>
            </div>
            <div className="modal-body py-0 mb-2 d-flex flex-column">
              <h6 className="modal-title text-center">Pairfonts is a tool to help you pair Google Fonts</h6>
            </div>
            <div className="modal-footer d-flex justify-content-center border-0">
              <div className="d-flex flex-column">
                <Button
                  mode={BUTTON_MODE_PRIMARY}
                  size={BUTTON_SIZE_SMALL}
                  onClick={this.startTutorial}
                >
                  Tell me more!
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderThanks() {
    if (window) {
      var j = window.$;
      j(`#${this.props.id}`).modal('show');
    }
    return (
      <div className="modal fade" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header border-0">
              <Typography.Header2 className="text-center w-100">
                That's it <EmojiWrapper description="Hey">🌈</EmojiWrapper>
              </Typography.Header2>
            </div>
            <div className="modal-body py-0 mb-2 d-flex flex-column">
              <h6 className="modal-title text-center">I hope pairfonts saves you time and makes you happy</h6>
            </div>
            <div className="modal-footer d-flex justify-content-center border-0">
              <div className="d-flex flex-column">
                <Button
                  mode={BUTTON_MODE_PRIMARY}
                  size={BUTTON_SIZE_SMALL}
                  onClick={this.resetOnboarding}
                >
                  Use Pairfonts
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderComp() {
    if (this.state.showThanks) {
      return this.renderThanks();
    }
    const step = this.state;

    switch(step) {
      case 1: {
        return this.renderIntro();
      };
      default: {
        return this.renderIntro();
      };
    }
  }
  render() {
    return this.renderComp();
  }
}
