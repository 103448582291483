import React from 'react';

const Column = ({
  className,
  size,
  largeSize,
  largeOffset,
  mediumSize,
  mediumOffset,
  smallSize,
  smallOffset,
  ...rest,
}) => {
  let colClassName = `col col-${size} ${className}`;
  if (largeSize) {
    colClassName = `${colClassName} col-lg-${largeSize}`;
  }
  if (largeOffset) {
    colClassName = `${colClassName} offset-lg-${largeOffset}`;
  }
  if (mediumSize) {
    colClassName = `${colClassName} col-md-${mediumSize}`;
  }
  if (mediumOffset) {
    colClassName = `${colClassName} offset-md-${mediumOffset}`;
  }
  if (smallSize) {
    colClassName = `${colClassName} col-sm-${smallSize}`;
  }
  if (smallOffset) {
    colClassName = `${colClassName} offset-sm-${smallOffset}`;
  }
  return (
    <div className={colClassName} {...rest}/>
  );
}

Column.defaultProps = {
  className: '',
  size: 12,
  style: {},
};

export default Column;
