import React from 'react';

const Paragraph = ({ className, color, ...rest}) => (
  <p className={`paragraph ${color} ${className}`} {...rest} />
);

Paragraph.defaultProps = {
  color: 'grey',
  className: '',
};

export default Paragraph;