import React from 'react';

const ToolbarItemGroup = ({ id, children }) => (
  <ul id={id} className="navbar-nav mt-2 mt-lg-0 toolbar-item-group">
    {
      children
    }
  </ul>
);

export default ToolbarItemGroup;
