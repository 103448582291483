import React, { Component } from 'react';

import Grid from '../../components/Grid';
import TextComp from '../../components/TextComp';

import './Editor.css';

export default class Editor extends Component {
  // Utils
  fontOptions() {
    if (this.props.loaded && this.props.fonts) {
      const options = this.props.fonts.map(font => ({
        label: font.family,
        value: font.family,
      }));
      return options;
    } else {
      return [];
    }
  }

  // Render Methods
  renderComp(comp) {
    return (
      <Grid.Column key={comp.index}>
        <TextComp {...comp} onElementClick={this.props.onElementClick} />
      </Grid.Column>
    );
  }
  renderComps() {
    return this.props.comps.map(comp => this.renderComp(comp));
  }
  render() {
    return (
      <div className="editor">
        <Grid.Row className="editor-playground">
          <Grid.Column>
            {
              this.renderComps()
            }
          </Grid.Column>
        </Grid.Row>
      </div>
    );
  }
}
