const API_URL = 'https://www.googleapis.com/webfonts/v1/webfonts';
// const API_KEY = process.env.REACT_APP_GOOGLE_FONTS_API_KEY;
const API_KEY = 'AIzaSyAp8VZiHo9sy1e8znFJH_wbsVTWk3wRNPM';

const sortBys = [
  'alpha', 'date', 'popularity', 'style', 'trending',
];

const getApiUrl = (sortBy) => {
  let url = `${API_URL}?key=${API_KEY}`;

  if (sortBys.includes(sortBy)) {
    url = `${url}&sort=${sortBy}`;
  } else {
    if (typeof sortBy !== 'undefined') {
      console.warn(`Cannot sort by ${sortBy}. Supported types are ${sortBys.join(',')}`);
    }
  }
  return url;
};

const GoogleFontsApi = {
  fetchFonts: (sortBy) => {
    return new Promise((resolve, reject) => {
      const apiUrl = getApiUrl(sortBy);
      fetch(apiUrl)
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(e => {
          console.error(`Error fetching fonts from google: ${e.message}`);
          reject(e);
        });
    });
  },
};

export default GoogleFontsApi;
