export default {
  short: [
    'This is SPARTA!!',
    'I’ll make him an offer he can’t refuse',
    'Why so serious? Let’s put a smile on that face.',
    'My Precious',
    'Luke I am your father',
    'Say hello to my little friend',
    'The name’s Bond, James Bond',
    'I will have my vengeance, in this life or the next',
    'I’ll be back',
    'hasta la vista baby',
    'I must break you',
    'Great men are not born great, they grow great',
    'May the Force be with you',
    'The first rule of Fight Club is you do not talk about Fight Club',
    'And why do we fall Bruce? So we can learn to pick ourselves up',
    'It’s not who I am underneath but what I do that defines me',
    'If you are good at something never do it for free',
    'All we have to decide is what to do with the time that is given to us',
    'Love means never having to say you\'re sorry',
    'They may take our lives, but they\'ll never take our freedom!',
    'They call me Mister Tibbs!',
    'I drink your milkshake!',
    'Chewie, we\'re home.',
    'They call it a Royale with cheese.',
    'Magic Mirror on the wall, who is the fairest one of all?',
    'Just when I thought I was out, they pull me back in.',
    'Wax on, wax off',
    'I\'m having an old friend for dinner.',
    'I\'m the king of the world!',
    'Hello. My name is Inigo Montoya. You killed my father. Prepare to die.',
    'You\'re gonna need a bigger boat.',
    'Just because someone stumbles and loses their path, doesn\'t mean they\'re lost forever',
    'Most of life\'s burdens, with a little help, can become a gift',
    'I found it is the small everyday deeds of ordinary folk that keep the darkness at bay',
    'I have to believe that when things are bad I can change them',
    'My mind rebels at stagnation. Give me problems, give me work. The sooner the better',
    'Do, or do not. There is no \'try.\'',
    'Don\'t tell me I can\'t do it; don\'t tell me it can\'t be done!',
    'Your dignity\'s inside you. Nobody can take something away from you you don\'t give them',
  ],
  long: [
    'This is what I believe to be true. This is what I learned in the hospital. You have to do everything you can, you have to work your hardest, and if you do, if you stay positive, you have a shot at a silver lining',
    'What is the most resilient parasite? Bacteria? A virus? An intestinal worm? An idea. Resilient... highly contagious. Once an idea has taken hold of the brain it\'s almost impossible to eradicate. An idea that is fully formed--fully understood--that sticks.',
    'Find a truly original idea. It is the only way I will ever distinguish myself. It is the only way I will ever matter.',
    'You don\'t have to be the bad guy. You are the most talented, most interesting, and most extraordinary person in the universe. And you are capable of amazing things. Because you are the Special. And so am I. And so is everyone. The prophecy is made up, but it\'s also true. It\'s about all of us. Right now, it\'s about you. And you... still... can change everything.',
    'Sometimes it is the people who no one imagines anything of who do the things that no one can imagine.',
    'There should be no boundaries to human endeavor. We are all different. However bad life may seem, there is always something you can do, and succeed at. While there\'s life, there is hope',
    'Most people live life on the path we set for them. Too afraid to explore any other. But once in a while people like you come along and knock down all the obstacles we put in your way. People who realize free will is a gift that you\'ll never know how to use until you fight for it.',
    'So avoid using the word ‘very’ because it’s lazy. A man is not very tired, he is exhausted. Don’t use very sad, use morose. The language was invented for one reason, boys - to woo women - and, in that endeavor, laziness will not do. It also won’t do in your essays',
    'Hope is a good thing. Maybe even the best of things and good things never die',
    'The greatest trick the devil ever pulled was convincing the world he didn\'t exist.',
    'Oh yes, the past can hurt. But you can either run from it, or learn from it',
    'Nobody is gonna hit as hard as life, but it ain’t how hard you can hit. It’s how hard you can get hit and keep moving forward. It’s how much you can take, and keep moving forward. That’s how winning is done.',
    'Don’t ever let somebody tell you you can’t do something, not even me. Alright? You dream, you gotta protect it. People can’t do something themselves, they wanna tell you you can’t do it. If you want something, go get it',
    'I guess it comes down to a simple choice, really. Get busy living or get busy dying.',
    'Courage is not living without fear. Courage is being scared to death and doing the right thing anyway.',
    'One day your life will flash before your eyes. Make sure it’s worth watching',
    'Honestly, its the honest ones you want to watch out for, because you can never predict when they are going to do something incredibly stupid',
    'The only thing standing between you and your goal is the bullshit story you keep telling yourself as to why you can’t achieve it',
    'Act as if !! Act as if you’re a wealthy man, rich already, and then you’ll surely become rich. Act as if you have unmatched confidence and then people will surely have confidence in you',
    'No matter what happened to you in your past, you are not your past, you are the resources and the capabilities you glean from it. And that is the basis for all change',
    'Successful people are 100% convinced that they are masters of their own destiny, they are not creatures of circumstance, they create circumstance, if the circumstance around them suck they change them',
    'Hey. Don\'t ever let somebody tell you... You can\'t do something. Not even me. All right? You got a dream... You gotta protect it. People can\'t do somethin\' themselves, they wanna tell you you can\'t do it. If you want somethin\', go get it. Period.',
    'I suppose in the end the whole of life becomes and act of letting go. But what always hurts the most is not taking a moment to say goodbye',
    'A hero can be anyone. Even a man doing something as simple and reassuring as putting a coat around a young boy’s shoulders to let him know that the world hadn’t ended',
    'If you let my daughter go now, that\'ll be the end of it. I will not look for you, I will not pursue you. But if you don\'t, I will look for you, I will find you, and I will kill you',
    'My name is Maximus Decimus Meridius, commander of the Armies of the North, General of the Felix Legions and loyal servant to the true emperor, Marcus Aurelius. Father to a murdered son, husband to a murdered wife. And I will have my vengeance, in this life or the next.',
    'You are not special. You\'re not a beautiful and unique snowflake. You\'re the same decaying organic matter as everything else. We\'re all part of the same compost heap. We\'re all singing, all dancing crap of the world.',
  ],
};
