import React, { Component } from 'react';

import Button from '../Button';
import EmojiWrapper from '../EmojiWrapper';

import {
  BUTTON_MODE_TERTIARY,
} from '../../utils/Constants';

import './FontSearch.css';

export default class FontSearch extends Component {
  constructor(props) {
    super(props);

    const mode = props.defaultSearchTerm.trim() === '' ? 'button' : 'form';

    this.state = {
      mode,
      searchTerm: props.defaultSearchTerm,
    };
    this.changeModeToForm = this.changeModeToForm.bind(this);
    this.changeModeToButton = this.changeModeToButton.bind(this);
    this.handleSearchTermChange = this.handleSearchTermChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.searchBarRef = React.createRef();
  }

  // State manipulators
  changeModeToForm(e) {
    e.preventDefault();
    this.setState({
      mode: 'form',
    });
  }
  changeModeToButton(e) {
    e.preventDefault();
    this.setState({
      mode: 'button',
    });
  }

  // Event Handlers
  handleSearchTermChange(e) {
    if (this.props.onSearch) {
      this.props.onSearch(e.target.value);
    }
  }
  handleFormSubmit(e) {
    const searchTerm = this.searchBarRef.current.value;
    this.props.onSearch(searchTerm);

    this.setState({
      searchTerm,
    });

    this.changeModeToButton(e);
  }

  renderButton() {
    return (
      <Button className="search-form-button" mode={BUTTON_MODE_TERTIARY} onClick={this.changeModeToForm}>
        <EmojiWrapper>🔍</EmojiWrapper>
      </Button>
    );
  }
  renderForm() {
    return (
      <form className="font-search-form" onSubmit={this.handleFormSubmit}>
        <input
          type="search"
          className="form-control search-bar"
          placeholder="🔍 Find me some Google Fonts..."
          onChange={this.handleSearchTermChange}
          ref={this.searchBarRef}
          defaultValue={this.state.searchTerm}
        />
      </form>
    );
  }
  render() {
    let component;

    if (this.state.mode === 'button') {
      component = this.renderButton();
    } else {
      component = this.renderForm();
    }
    return (
      <div className="font-search-form-wrapper">
        {component}
      </div>
    );
  }
}
