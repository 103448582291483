const BUTTON_MODE_PRIMARY = 'primary';
const BUTTON_MODE_SECONDARY = 'secondary';
const BUTTON_MODE_TRANSPARENT = 'transparent';
const BUTTON_MODE_TERTIARY = 'tertiary';
const BUTTON_MODE_SUBTLE = 'subtle';
const BUTTON_SIZE_REGULAR = 'regular';
const BUTTON_SIZE_SMALL = 'small';
const BUTTON_SIZE_LARGE = 'large';
const MENU_TYPE_TEXT_ALIGNMENT = 'text-alignment';
const MENU_TYPE_TEXT_LINE_HEIGHT = 'text-line-height';
const MENU_TYPE_TEXT_SIZE = 'text-size';
const MENU_TYPE_FONT_VARIANT = 'font-variant';
const MENU_TYPE_LETTER_SPACING = 'letter-spacing';
const MENU_TYPE_LOCK = 'lock';
const MENU_TYPE_REMOVE = 'bin';
const NAVBAR_MODE_ACTIVE = 'ACTIVE';
const NAVBAR_MODE_IN_ACTIVE = 'IN-ACTIVE';
const TOOLBAR_MODE_MENU = 'menu';
const TOOLBAR_MODE_FORM = 'form';
const TOOLBAR_MODE_SELECTOR = 'selector';
const TYPOGRAPHY_BLUE = 'blue';
const EVENT_INSERT_COMPONENT = 'insert component';
const EVENT_REMOVE_COMPONENT = 'remove component';
const EVENT_INTRO_STARTED = 'start introduction';
const EVENT_INTRO_FINISH = 'finish introduction';
const EVENT_INTRO_CLOSED = 'close introduction';
const EVENT_SELECT_FONT = 'select font';
const EVENT_RANDOMIZE_FONTS = 'randomize fonts';
const EVENT_RANDOMIZE_PHRASES = 'randomize phrases';
const EVENT_COPY_HTML = 'copy html';
const EVENT_COPY_CSS = 'copy css';
const EVENT_OPEN_GOOGLE_FONTS = 'open google fonts';
const EVENT_PRINT = 'print';
const EVENT_CHANGE_ALIGNMENT = 'change alignment';
const EVENT_CHANGE_VARIANT = 'change variant';
const EVENT_CHANGE_FONT_SIZE = 'change font size';
const EVENT_CHANGE_LINE_SPACING = 'change line height';
const EVENT_CHANGE_LETTER_SPACING = 'change letter spacing';
const EVENT_CHANGE_LOCK = 'change lock';
const EVENT_SEARCH_FONT = 'search';

export {
  BUTTON_MODE_PRIMARY,
  BUTTON_MODE_SECONDARY,
  BUTTON_MODE_TRANSPARENT,
  BUTTON_MODE_TERTIARY,
  BUTTON_MODE_SUBTLE,
  BUTTON_SIZE_REGULAR,
  BUTTON_SIZE_SMALL,
  BUTTON_SIZE_LARGE,
  MENU_TYPE_TEXT_ALIGNMENT,
  MENU_TYPE_TEXT_LINE_HEIGHT,
  MENU_TYPE_TEXT_SIZE,
  MENU_TYPE_FONT_VARIANT,
  MENU_TYPE_LETTER_SPACING,
  MENU_TYPE_LOCK,
  MENU_TYPE_REMOVE,
  NAVBAR_MODE_ACTIVE,
  NAVBAR_MODE_IN_ACTIVE,
  TOOLBAR_MODE_MENU,
  TOOLBAR_MODE_FORM,
  TOOLBAR_MODE_SELECTOR,
  TYPOGRAPHY_BLUE,
  EVENT_INSERT_COMPONENT,
  EVENT_REMOVE_COMPONENT,
  EVENT_INTRO_STARTED,
  EVENT_INTRO_FINISH,
  EVENT_INTRO_CLOSED,
  EVENT_SELECT_FONT,
  EVENT_RANDOMIZE_FONTS,
  EVENT_RANDOMIZE_PHRASES,
  EVENT_COPY_HTML,
  EVENT_COPY_CSS,
  EVENT_OPEN_GOOGLE_FONTS,
  EVENT_PRINT,
  EVENT_CHANGE_ALIGNMENT,
  EVENT_CHANGE_VARIANT,
  EVENT_CHANGE_FONT_SIZE,
  EVENT_CHANGE_LINE_SPACING,
  EVENT_CHANGE_LETTER_SPACING,
  EVENT_CHANGE_LOCK,
  EVENT_SEARCH_FONT,
};
