import mixpanel from 'mixpanel-browser';

const ActivityHelper = {
  t: (event, data = {}) => {
    const { NODE_ENV: env } = process.env;

    if (env === 'production') {
      mixpanel.track(event, {
        ...data,
        referrer: document.referrer,
      });
    } else {
      console.log(`Tracking ${event}`);
    }
  },
}

export default ActivityHelper;
