import FancyHeader1 from './FancyHeader1';
import Header3 from './Header3';
import Header2 from './Header2';
import Paragraph from './Paragraph';

export default {
  FancyHeader1,
  Paragraph,
  Header3,
  Header2,
};

