import React from 'react';
import styled from 'styled-components';

import Logo from '../../assets/logo/Logo-v2.svg';

const StyledLogoType = styled.img`
  height: 18px;
`;

const LogoType = () => (
  <StyledLogoType src={Logo} alt="Pairfonts Logo" />
);

export default LogoType;
