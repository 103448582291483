import React from 'react';

import './EmojiWrapper.css';

const EmojiWrapper = ({ description, ...rest }) => (
  <span className="emoji-wrapper" role="img" aria-label={description} title={description} {...rest} />
);

EmojiWrapper.defaultProps = {
  children: '🤷‍♂️',
  description: 'Forgot to put emoji in',
}
export default EmojiWrapper;