import React from 'react';

const ToolbarItem = ({ key, label, icon, onClick, isActive, className, tooltip, border, ...rest }) => {
  let borderClassname = 'toolbar-item--no-border';
  if (border === 'left') {
    borderClassname = 'toolbar-item--border-left';
  } else if (border === 'right') {
    borderClassname = 'toolbar-item--border-right';
  }
  const compProps = {
    key,
    className: `d-flex nav-item toolbar-item ${isActive ? 'toolbar-item--active' : ''} ${borderClassname}`,
  };
  if (tooltip !== undefined) {
    compProps['data-toggle'] = 'tooltip';
    compProps['data-placement'] = 'top';
    compProps.title = tooltip;
  }
  return (
    <li {...compProps}>
      <button className={`btn btn-link nav-link d-flex justify-content-center align-items-center ${className ? className : ''}`} onClick={onClick} {...rest}>
        <span className={`toolbar-item-icon ${icon}`}></span>
        {label ? label : ''}
      </button>
    </li>
  );
};

export default ToolbarItem;