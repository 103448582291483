import React from 'react';
import Helmet from 'react-helmet';
import { withPrefix } from 'gatsby';
import mixpanel from "mixpanel-browser";

import AppleTouchIcon from '../../assets/favicons/apple-touch-icon.png';
import Favicon32 from '../../assets/favicons/favicon-32x32.png';
import Favicon16 from '../../assets/favicons/favicon-16x16.png';
import SafariPinnedTab from '../../assets/favicons/safari-pinned-tab.svg';
import FaviconIco from '../../assets/favicons/favicon.ico';
import PreviewImage from '../../assets/img/preview_image.png';

const GlobalHead = () => {
  mixpanel.init('2bb30cad12786ab554a6ae6cdfc52f53');
  return (
    <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href={AppleTouchIcon} />
        <link rel="icon" type="image/png" href={Favicon32} sizes="32x32" />
        <link rel="icon" type="image/png" href={Favicon16} sizes="16x16" />
        <link rel="manifest" href={withPrefix('/favicons/manifest.json')} />
        <link rel="mask-icon" href={SafariPinnedTab} color="#181399" />
        <link rel="shortcut icon" href={FaviconIco} />
        <meta name="msapplication-config" content="/static/favicons/browserconfig.xml" />
        <meta name="theme-color" content="#181399" />
        <meta property="og:site_name" content="Pairfonts" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={PreviewImage} />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />

        <script type="application/ld+json">
            {
                `{
                    "@context": "http://schema.org",
                    "@type": "Website",
                    "url": "https://pairfonts.com"
                }`
            }
        </script>
    </Helmet>
  );
}

export default GlobalHead;
