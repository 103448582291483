let Webfont;
let loadingWebfont = false;
const loadFont = () => new Promise((resolve, reject) => {
  if (window) {
    import('webfontloader')
      .then(webfontLoader => {
        Webfont = webfontLoader;
        loadingWebfont = false;
        resolve(Webfont);
      });
  } else {
    reject(new Error('No window, no webfont'));
  }
});

const GOOGLE_FONT = 'google';
const downloadFont = async (source, name, category) => {
  if (window) {
    if (!Webfont) {
      if (!loadingWebfont) {
        loadingWebfont = true;
        try {
          Webfont = await loadFont();
          downloadFont(source, name, category);
        } catch(e) {
          console.error('Error fetching webfont');
          console.error(e);
        }
      }
    } else {
      if (source === GOOGLE_FONT) {
        Webfont.load({
          google: {
            families: [name, category],
          },
        });
      }
    }
  } else {
    console.error('Cannot load font, window not found');
  }
};

export default {
  download: downloadFont,
};
