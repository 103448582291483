import React from 'react';

const Header3 = ({ className, color, children, ...rest}) => (
  <h3 className={`header-3 ${color} ${className}`} {...rest}>
    {
      children
    }
  </h3>
);

Header3.defaultProps = {
  color: 'grey',
  className: '',
};

export default Header3;