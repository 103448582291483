import React, { Component } from 'react';
import Button from '../Button';
import EmojiWrapper from '../EmojiWrapper';

import './TextCompToolbar.css';

import {
  MENU_TYPE_TEXT_ALIGNMENT,
  MENU_TYPE_TEXT_LINE_HEIGHT,
  MENU_TYPE_TEXT_SIZE,
  MENU_TYPE_FONT_VARIANT,
  MENU_TYPE_LETTER_SPACING,
  MENU_TYPE_LOCK,
  MENU_TYPE_REMOVE,
  TOOLBAR_MODE_MENU,
  TOOLBAR_MODE_FORM,
  TOOLBAR_MODE_SELECTOR,
  BUTTON_MODE_PRIMARY,
  BUTTON_MODE_SECONDARY,
  BUTTON_MODE_TRANSPARENT,
} from '../../utils/Constants';

const ToolbarItem = ({ iconClassName, title, type, onClick }) => {
  const handleOnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onClick(type);
  }
  return (
    <button type="button" className="btn text-comp-toolbar-item" onClick={handleOnClick} title={title}>
      <span className={iconClassName}></span>
    </button>
  );
}

const WeightItem = ({ style, weight, currentWeight, onChange, compIndex }) => {
  const itemStyle = {
    ...style,
    fontWeight: weight,
  };
  if (weight.indexOf('italic') > -1) {
    itemStyle.fontStyle = 'italic';
    itemStyle.fontWeight = weight.split('italic')[0];
  }
  let className = 'text-comp-toolbar-selector-list-item';
  if (weight === currentWeight) {
    className = `${className} text-comp-toolbar-selector-list-item--selected`;
  }
  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (typeof onChange === 'function') {
      onChange(compIndex, weight);
    }
  }
  return (
    <Button
      className={className}
      style={itemStyle}
      mode={BUTTON_MODE_TRANSPARENT}
      onClick={onClick}
    >
      {
        weight
      }
    </Button>
  );
}

export default class Toolbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: props.mode,
    };
  }

  // Utils
  getIconClassNameForMenuType(type, value) {
    switch(type) {
      case MENU_TYPE_TEXT_ALIGNMENT: {
        let iconClassName = ''
        if (value === 'left') {
          iconClassName = 'icon-text-align-left';
        } else if (value === 'center') {
          iconClassName = 'icon-text-align-center';
        } else if (value === 'right') {
          iconClassName = 'icon-text-align-right';
        }
        return iconClassName;
      }
      case MENU_TYPE_LOCK: {
        return value ? 'icon-lock' : 'icon-unlock';
      }
      case MENU_TYPE_FONT_VARIANT: {
        return 'icon-font-variant';
      }
      case MENU_TYPE_TEXT_SIZE: {
        return 'icon-font-size';
      }
      case MENU_TYPE_TEXT_LINE_HEIGHT: {
        return 'icon-line-height';
      }
      case MENU_TYPE_LETTER_SPACING: {
        return 'icon-letter-spacing';
      }
      case MENU_TYPE_REMOVE: {
        return 'icon-bin';
      }
      default:
        return '';
    }
  }
  resetToolbar(e) {
    if (e) {
      this.stopPropagation(e);
    }
    this.setState({
      mode: TOOLBAR_MODE_MENU,
    });
  }

  // Event handlers
  stopPropagation(e) {
    e.preventDefault();
    e.stopPropagation();
  }
  handleFormButtonClick(e) {
    this.stopPropagation(e);

    if (typeof this.state.onSubmit === 'function') {
      const value = document.getElementById('text-comp-toolbar-form-input').value;
      this.state.onSubmit(this.props.compIndex, value);
    }
    this.resetToolbar();
  }
  handleSelectorClicked(compIndex, newValue) {
    this.setState({
      currentValue: newValue,
    });
    if (typeof this.state.onSubmit === 'function') {
      this.state.onSubmit(compIndex, newValue);
    }
  }

  // Render methods
  renderItems() {
    return this.props.items.map((item) => {
      const { type, value } = item;
      const onClick = (type) => {
        if (type === MENU_TYPE_TEXT_ALIGNMENT) {
          if (typeof item.onChange !== 'undefined') {
            item.onChange(this.props.compIndex);
          }
        } else if (type === MENU_TYPE_LOCK) {
          if (typeof item.onChange !== 'undefined') {
            item.onChange(this.props.compIndex);
          }
        } else if (type === MENU_TYPE_TEXT_LINE_HEIGHT) {
          // Convert toolbar to form mode
          this.setState({
            mode: TOOLBAR_MODE_FORM,
            placeholder: 'Enter line height',
            currentValue: value,
            onSubmit: item.onChange,
            type: 'number',
            suffix: undefined,
          });
        } else if (type === MENU_TYPE_TEXT_SIZE) {
          // Convert toolbar to form mode
          this.setState({
            mode: TOOLBAR_MODE_FORM,
            placeholder: 'Enter text size',
            currentValue: value,
            onSubmit: item.onChange,
            type: 'number',
            suffix: 'px',
          });
        } else if (type === MENU_TYPE_FONT_VARIANT) {
          const items = Array.from(new Set(this.props.variants.filter(variant => {
            // return variant !== 'regular' && variant !== 'italic';
            return true;
          })));
          this.setState({
            mode: TOOLBAR_MODE_SELECTOR,
            currentValue: value,
            items: items,
            onSubmit: item.onChange,
          });
        } else if (type === MENU_TYPE_LETTER_SPACING) {
          // Convert toolbar to form mode
          this.setState({
            mode: TOOLBAR_MODE_FORM,
            placeholder: 'Enter letter spacing',
            currentValue: value,
            onSubmit: item.onChange,
            type: 'number',
            suffix: 'rem',
          });
        } else if (type === MENU_TYPE_REMOVE) {
          if (typeof item.onChange !== 'undefined') {
            item.onChange(this.props.compIndex);
          }
        }
      }
      const iconClassName = this.getIconClassNameForMenuType(type, value);
      return <ToolbarItem key={type} title={item.title} type={item.type} iconClassName={iconClassName} onClick={onClick} />
    });
  }
  renderSelectorItems() {
    const style = {
      fontFamily: this.props.fontFamily,
    };
    return this.state.items.map((item) => {
      return (
        <WeightItem
          key={item}
          style={style}
          weight={item}
          currentWeight={this.state.currentValue}
          onChange={this.handleSelectorClicked.bind(this)}
          compIndex={this.props.compIndex}
        />
      );
    });
  }
  renderMenu(className) {
    return (
      <div className={`text-comp-toolbar btn-group btn-group-sm ${className}`} role="group" aria-label="Font Menu">
        {
          this.renderItems()
        }
      </div>
    );
  }
  renderForm(className) {
    let inputComp = (
      <input
        id="text-comp-toolbar-form-input"
        type={this.state.type}
        className="form-control text-comp-toolbar-form-input"
        placeholder={this.state.placeholder}
        defaultValue={this.state.currentValue}
        onClick={this.stopPropagation}
      />
    );
    let inputGroup = (
      <div className="text-comp-toolbar-form-input-group input-group">
        <input
          id="text-comp-toolbar-form-input"
          type={this.state.type}
          className="form-control text-comp-toolbar-form-input text-comp-toolbar-form-input--no-margin"
          placeholder={this.state.placeholder}
          defaultValue={this.state.currentValue}
          onClick={this.stopPropagation}
        />
        <div className="input-group-append text-comp-toolbar-form-input-group-append">
          {this.state.suffix}
        </div>
      </div>
    );
    return (
      <div className={`text-comp-toolbar ${className}`}>
        <form className="text-comp-toolbar-form">
          {
            this.state.suffix ?
            inputGroup :
            inputComp
          }
          <Button
            className="text-comp-toolbar-form-button"
            mode={BUTTON_MODE_PRIMARY}
            onClick={this.handleFormButtonClick.bind(this)}
          >
            Set
          </Button>
        </form>
      </div>
    );
  }
  renderSelector(className) {
    return (
      <div className={`text-comp-toolbar btn-group btn-group-sm ${className}`} role="group" aria-label="Font Menu">
        <div className="text-comp-toolbar-selector">
          <Button
            className="text-comp-toolbar-form-button"
            mode={BUTTON_MODE_SECONDARY}
            onClick={this.resetToolbar.bind(this)}
            style={{
              padding: '5px',
              fontSize: '15px',
              lineHeight: '0',
            }}
          >
          <EmojiWrapper>
            ⬅
          </EmojiWrapper>
          </Button>
          <div className="text-comp-toolbar-selector-list">
            {
              this.renderSelectorItems()
            }
          </div>
        </div>
      </div>
    );
  }
  render() {
    let className = '';
    if (this.props.isActive) {
      className = "text-comp-toolbar--active";
    }
    const { mode } = this.state;
    let component = '';
    switch(mode) {
      case TOOLBAR_MODE_MENU:
        component = this.renderMenu(className);
        break;
      case TOOLBAR_MODE_FORM:
        component = this.renderForm(className);
        break;
      case TOOLBAR_MODE_SELECTOR:
        component = this.renderSelector(className);
        break;
      default:
        break;
    }

    return component;
  }
}

Toolbar.defaultProps = {
  items: [],
  mode: TOOLBAR_MODE_MENU,
};

