import React, { Component } from 'react';

import EmojiWrapper from '../EmojiWrapper';
import FontDownloader from '../../utils/fontDownloader';

import './FontList.css';

class FontItem extends Component {
  componentDidMount() {
    const fontText = this.getFontName();
    FontDownloader.download('google', fontText, this.props.font.category);
  }

  getFontName() {
    const { family, kind, variants } = this.props.font;
    let variant = variants[0] ;

    if (variants.includes('300')) {
      variant = '300';
    } else if (variants.includes('regular')) {
      variant = 'regular';
    }
    if (kind.includes('web')) {
      return `${family}:${variant}`;
    } else {
      return;
    }
  }
  loadFullFont(font) {
    const variants = font.variants;
    FontDownloader.download('google', `${this.props.font.family}:${variants.join(',')}`, this.props.font.category);
  }

  handleSelect(e) {
    e.preventDefault();
    e.stopPropagation();

    // Load font
    this.loadFullFont(this.props.font);
    this.props.onSelect(this.props.font);
  }
  render() {
    const style = {
      fontFamily: `"${this.props.font.family}", Lato`,
      fontWeight: 'normal',
      color: 'white',
      textDecoration: 'none',
    };
    if (this.props.selectedFont && this.props.font.family === this.props.selectedFont.family) {
      style.backgroundColor = 'white';
      style.color = '#1F1F33';
    }
    return (
      <button className="btn btn-link font-item" style={style} onClick={this.handleSelect.bind(this)}>
        {this.props.font.family}
      </button>
    );
  }
}

export default class FontList extends Component {
  static getDerivedStateFromProps(props, state) {
    // Every time a re render happens, refresh
    // scrollTriggered to false. Assumption is
    // that re render means new fonts are loaded.
    return {
      ...state,
      scrollTriggered: false,
    };
  }
  constructor(props) {
    super(props);
    this.state = {
      scrollTriggered: false,
    };

    this.onScroll = this.onScroll.bind(this);
  }
  // Event handlers
  onScroll(e) {
    const threshold = 100;
    if ((e.target.clientWidth + e.target.scrollLeft + threshold) >= e.target.scrollWidth) {
      if (!this.state.scrollTriggered) {
        this.setState({
          scrollTriggered: true,
        });
        if (this.props.loadNextPage) {
          this.props.loadNextPage();
        }
      } else {
        console.log('Already loading: Will not trigger');
      }
    }
  }
  // Render methods
  renderFonts() {
    const { fonts, onFontSelect, selectedFont } = this.props;
    return fonts.map((font) => {
      return <FontItem key={font.family} font={font} onSelect={onFontSelect} selectedFont={selectedFont} />
    });
  }
  renderEmptyState() {
    return (
      <div className="font-list-empty-state">
        <EmojiWrapper description="information">💁‍♀️</EmojiWrapper>&nbsp;
        <EmojiWrapper description="not found">🙅‍♀️</EmojiWrapper>&nbsp;
        <EmojiWrapper description="confused">🤷‍</EmojiWrapper>&nbsp;
        <EmojiWrapper description="sad">🙍‍♀️</EmojiWrapper>
      </div>
    )
  }
  renderList() {
    return (
      <div className = "font-list" onScroll={this.onScroll}>
        {
          this.renderFonts(this.props.fonts)
        }
      </div>
    );
  }
  render() {
    const { fonts } = this.props;
    const component = fonts.length > 0?
        this.renderList() :
        this.renderEmptyState();
    return component;
  }
}
