import React from 'react';

import Button from '../../components/Button';
import { BUTTON_MODE_PRIMARY, BUTTON_SIZE_SMALL } from '../../utils/Constants';

const ConfirmationModal = ({ title, emoji, description }) => (
  <div className="modal fade" id="confirmation-modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
      <div className="modal-content">
        <div className="modal-body d-flex flex-column">
          <br />
          <h1 className="modal-title text-center">{title}</h1>
          <h6 className="modal-title text-center">{description}</h6>
        </div>
        <div className="modal-footer d-flex justify-content-center border-0">
          <div className="d-flex flex-column">
            <Button
              mode={BUTTON_MODE_PRIMARY}
              size={BUTTON_SIZE_SMALL}
              data-dismiss="modal"
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ConfirmationModal;
